import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import classnames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Top from 'Components/top';
import LoadingOverlay from 'Components/loading-overlay';
import Button from 'Components/button';
import loc from 'Components/languages';
import { getCashMovementDetails, confirmCashMovement } from 'Redux/actions';
import * as cons from 'Redux/constants';
import { extractDateFromDate, convertFloatToPrice } from 'Helpers/helpers';
import RowCashMovementItem from './components/RowCashMovementItem.jsx';
import ModalSalesman from '../sales-order/salesman/components/ModalSalesman.jsx';
import styles from './PageCashMovementEdit.less';

class PageCashMovementEdit extends PureComponent {
	constructor() {
		super();
		this.defaultCashMovementItem = {
			salesman_code: '',
			description: '',
			debit_amount: 0,
			credit_amount: 0,
		};
		this.state = {
			page: 1,
			isToday: false,
			cashMovement: new Map(),
			currentIndex: null,
			showModalSalesman: false,
		};
		this.isLoading = this.isLoading.bind(this);
		this.isReadOnly = this.isReadOnly.bind(this);
		this.isReadOnly = this.isReadOnly.bind(this);
		this.getDate = this.getDate.bind(this);
		this.getCashMovementMap = this.getCashMovementMap.bind(this);
		this.getCashMovementDetails = this.getCashMovementDetails.bind(this);
		this.onToggleModalSalesmanHandler = this.onToggleModalSalesmanHandler.bind(this);
		this.onSelectSalesmanHandler = this.onSelectSalesmanHandler.bind(this);
		this.onChangeCashMovementItemHandler = this.onChangeCashMovementItemHandler.bind(this);
		this.onRemoveCashMovementItemHandler = this.onRemoveCashMovementItemHandler.bind(this);
		this.onChangeDescriptionHandler = this.onChangeDescriptionHandler.bind(this);
		this.onClickAddHandler = this.onClickAddHandler.bind(this);
		this.onClickSaveHandler = this.onClickSaveHandler.bind(this);
		this.onClickRefreshHandler = this.onClickRefreshHandler.bind(this);
	}

	componentDidMount() {
		this.getCashMovementDetails();
	}

	componentDidUpdate(prevProps) {
		const cashMovementDetailsInfo = this.props.cashMovementDetailsInfo;
		const prevCashMovementDetailsInfo = prevProps.cashMovementDetailsInfo;
		const cashMovementConfirmInfo = this.props.cashMovementConfirmInfo;
		const prevCashMovementConfirmInfo = prevProps.cashMovementConfirmInfo;
		if (cashMovementDetailsInfo && cashMovementDetailsInfo !== prevCashMovementDetailsInfo) {
			if (cashMovementDetailsInfo.type === cons.GET_CASHMOVEMENT_DETAILS.SUCCESS) {
				const cashMovement = this.getCashMovementMap(cashMovementDetailsInfo.data);
				this.setState({
					isToday: moment(cashMovement.get('cash_movement_date')).isSame(moment(), 'day'),
					cashMovement: cashMovement,
				});
			}
		}

		if (cashMovementConfirmInfo && cashMovementConfirmInfo !== prevCashMovementConfirmInfo) {
			switch (cashMovementConfirmInfo.type) {
			case cons.CONFIRM_CASHMOVEMENT.SUCCESS:
				UIkit.notification('The cash movement is updated successfully.', { status: 'success' });
				this.onClickRefreshHandler();
				break;
			case cons.CONFIRM_CASHMOVEMENT.FAILURE:
				UIkit.notification('Error occured! The cash movement is NOT updated.', { status: 'danger' });
				break;
			}
		}
	}

	isLoading() {
		const { cashMovementConfirmInfo, cashMovementDetailsInfo } = this.props;
		return cashMovementConfirmInfo.isFetching || cashMovementDetailsInfo.isFetching;
	}

	isReadOnly() {
		const { isToday } = this.state;
		return !isToday;
	}

	getDate(props = null) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.date;
	}

	getCashMovementMap(cashMovementDetailsInfo) {
		let cashMovement = new Map(cashMovementDetailsInfo);
		cashMovement = cashMovement
			.set(
				'cash_movement_items',
				new List(
					cashMovement.get('cash_movement_items').map((cashMovementItem) => {
						let ret = new Map(cashMovementItem);
						ret = ret.set('time', moment(ret.get('time')));
						return ret;
					})
				)
			)
			.set(
				'payment_info',
				new List(
					cashMovement.get('payment_info').map((paymentInfo) => new Map(paymentInfo))
				)
			)
		;
		return cashMovement;
	}

	getCashMovementDetails() {
		const date = this.getDate();
		const param = {
			payload: {
				cash_movement_date: date,
			},
		};
		this.props.getCashMovementDetails(param);
	}

	onToggleModalSalesmanHandler(index) {
		const { showModalSalesman } = this.state;
		if (!showModalSalesman && index || index === 0) {
			this.setState({
				currentIndex: index,
				showModalSalesman: true,
			});
		} else {
			this.setState({
				currentIndex: null,
				showModalSalesman: false,
			});
		}
	}

	onSelectSalesmanHandler(salesman) {
		let { currentIndex, cashMovement } = this.state;
		let cashMovementItems = cashMovement.get('cash_movement_items');
		cashMovement = cashMovement.set(
			'cash_movement_items',
			cashMovementItems.set(
				currentIndex,
				cashMovementItems.get(currentIndex).set('salesman_code', salesman.user_id)
			)
		);
		this.setState({
			cashMovement,
			currentIndex: null,
			showModalSalesman: false,
		});
	}

	onChangeCashMovementItemHandler(index, field, value) {
		let { cashMovement } = this.state;
		let cashMovementItems = cashMovement.get('cash_movement_items');
		cashMovement = cashMovement.set(
			'cash_movement_items',
			cashMovementItems.set(
				index,
				cashMovementItems.get(index).set(field, value)
			)
		);
		this.setState({
			cashMovement,
		});
	}

	onRemoveCashMovementItemHandler(index) {
		const { cashMovement } = this.state;
		this.setState({
			cashMovement: cashMovement.set(
				'cash_movement_items',
				cashMovement.get('cash_movement_items').delete(index),
			)
		});
	}

	onChangeDescriptionHandler(e) {
		const { cashMovement } = this.state;
		this.setState({
			cashMovement: cashMovement.set('description', e.target.value),
		});
	}

	onClickAddHandler() {
		let { cashMovement } = this.state;
		cashMovement = cashMovement.set(
			'cash_movement_items',
			cashMovement.get('cash_movement_items').push(
				new Map(this.defaultCashMovementItem)
					.set('time', moment())
					.set('line_no', cashMovement.get('cash_movement_items').size)
			)
		);
		this.setState({
			cashMovement,
		});
	}

	onClickSaveHandler() {
		let { cashMovement } = this.state;
		const param = {
			payload: {
				cash_movement_date: extractDateFromDate(cashMovement.get('cash_movement_date'), 'yyyy-MM-dd'),
				description: cashMovement.get('description'),
				cash_movement_items: cashMovement
					.get('cash_movement_items')
					.sort((a, b) => {
						const aTime = a.get('time');
						const bTime = b.get('time');
						const aDescription = a.get('description');
						const bDescription = b.get('description');
						if (aTime < bTime) {
							return -1;
						} else if (aTime > bTime) {
							return 1;
						} else if (aDescription < bDescription) {
							return -1;
						} else if (aDescription > bDescription) {
							return 1;
						} else {
							return 0;
						}
					})
					.toJS()
					.map((cashMovementItem, index) => ({
						...cashMovementItem,
						debit_amount: parseFloat(cashMovementItem.debit_amount),
						credit_amount: parseFloat(cashMovementItem.credit_amount),
						line_no: index + 1,
						time: cashMovementItem.time && cashMovementItem.time.format('YYYY-MM-DD HH:mm:ss'),
					}))
				,
				last_updated_timestamp: cashMovement.get('last_updated_timestamp'),
			},
		};
		this.props.confirmCashMovement(param);
	}

	onClickRefreshHandler() {
		this.getCashMovementDetails();
	}

	render() {
		const { cashMovement, showModalSalesman } = this.state;
		const isLoading = this.isLoading();
		const isReadOnly = this.isReadOnly();
		let totalCreditAmount = 0;
		let totalDebitAmount = 0;
		cashMovement.get('cash_movement_items') && cashMovement.get('cash_movement_items').forEach((cashMovementItem) => {
			totalCreditAmount += parseFloat(cashMovementItem.get('credit_amount')) || 0;
			totalDebitAmount += parseFloat(cashMovementItem.get('debit_amount')) || 0;
		});
		let accumulate = cashMovement.get('opening') + cashMovement.get('net_pay_amount') + totalDebitAmount - totalCreditAmount;
		return (
			<Fragment>
				<LoadingOverlay active={ isLoading }>
					<div className="uk-flex uk-flex-column">
						<Top name="cashMovement">
							<div className="uk-width-expand uk-flex-inline uk-flex-right">
								<div className="uk-position-relative">
									<div
										className={ classnames('uk-padding-small', styles.button, 'uk-position-relative', 'uk-text-primary', isLoading && styles.disabled) }
										onClick={ this.onClickRefreshHandler }
									>
										<FontAwesomeIcon icon={ faSync } className="fa-2x" />
									</div>
								</div>
							</div>
						</Top>
						<div
							className={ classnames('uk-height-large', 'uk-overflow-auto', styles.container) }
							data-uk-height-viewport="offset-top: true; offset-bottom: 125px"
						>
							<div className="uk-flex">
								<div className="uk-width-1-2">
									<h3 className="uk-text-secondary uk-text-bold uk-text-uppercase uk-margin-remove-bottom">
										{ loc.recordInfo }
									</h3>
									<div className={ styles.recordInfo }>
										<div className={ styles.row }>
											<div className={ styles.title }>{ loc.shop }</div>
											<div className={ styles.content }>{ cashMovement.get('wh_desc') }</div>
										</div>
										<div className={ styles.row }>
											<div className={ styles.title }>{ loc.cashier }</div>
											<div className={ styles.content }>{ cashMovement.get('cashier_desc') }</div>
										</div>
										<div className={ styles.row }>
											<div className={ styles.title }>{ loc.currency }</div>
											<div className={ styles.content }>{ cashMovement.get('currency') }</div>
										</div>
										<div className={ styles.row }>
											<div className={ styles.title }>{ loc.date }</div>
											<div className={ styles.content }>{ cashMovement.get('cash_movement_date') ? extractDateFromDate(cashMovement.get('cash_movement_date'), 'yyyy-MM-dd') : '-' }</div>
										</div>
										<div className={ styles.row }>
											<div className={ styles.title }>{ loc.description }</div>
											<div className={ styles.content }>
												{
													isReadOnly ? (
														<div>
															{ cashMovement.get('description') }
														</div>
													) : (
														<textarea
															rows="4"
															cols="40"
															className={ classnames('uk-textarea', styles.descriptionTextArea) }
															placeholder={ loc.description }
															disabled={ isLoading }
															value={ cashMovement.get('description') || '' }
															onChange={ this.onChangeDescriptionHandler }
														/>
													)
												}
											</div>
										</div>
									</div>
								</div>
								<div className="uk-width-1-2">
									<h3 className="uk-text-secondary uk-text-bold uk-text-uppercase uk-margin-remove-bottom">
										{ loc.paymentInfo }
									</h3>
									<div className={ styles.paymentInfo }>
										{
											cashMovement.get('payment_info') && cashMovement.get('payment_info').map((payment, index) => (
												<div className={ styles.row } key={ `page-cash-movement-payment-info-${index}` }>
													<div className={ styles.title }>{ payment.get('pay_desc') }</div>
													<div className={ styles.content }>{ convertFloatToPrice(payment.get('amount')) }</div>
												</div>
											))
										}
									</div>
								</div>
							</div>

							<div className={ styles.summaryMiddle }>
								<div className={ styles.row }>
									<div className={ styles.title }>{ loc.paymentAmount }</div>
									<div className={ styles.content }>{ convertFloatToPrice(cashMovement.get('payment_amount')) }</div>
									<div className={ styles.title }>{ loc.opening }</div>
									<div className={ styles.content }>{ convertFloatToPrice(cashMovement.get('opening')) }</div>
								</div>
								<div className={ styles.row }>
									<div className={ styles.title }>{ loc.changeAmount }</div>
									<div className={ styles.content }>{ convertFloatToPrice(cashMovement.get('change_amount')) }</div>
									<div className={ styles.title }>{ loc.netPayAmount }</div>
									<div className={ styles.content }>{ convertFloatToPrice(cashMovement.get('net_pay_amount')) }</div>
								</div>
							</div>

							<div className={ styles.cashFlowEntry }>
								<h3 className="uk-text-secondary uk-text-bold uk-text-uppercase uk-margin-remove-bottom">
									{ loc.cashFlowEntry }
								</h3>
								<table className={ classnames('uk-table', 'uk-table-divider', styles.table) }>
									<thead>
										<tr>
											<th>{ loc.time }</th>
											<th>{ loc.salesman }</th>
											<th>{ loc.description }</th>
											<th>{ loc.debit }</th>
											<th>{ loc.credit }</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{
											cashMovement.get('cash_movement_items') && cashMovement.get('cash_movement_items').map((cashMovementItem, index) => (
												<RowCashMovementItem
													key={ `page-cash-movement-edit-item-${index}` }
													index={ index }
													isReadOnly={ isReadOnly }
													cashMovementItem={ cashMovementItem }
													onChange={ this.onChangeCashMovementItemHandler }
													onRemove={ this.onRemoveCashMovementItemHandler }
													onToggleModalSalesman={ this.onToggleModalSalesmanHandler }
												/>
											))
										}
									</tbody>
								</table>
							</div>
						</div>

						<div className={ styles.footer }>
							{
								!isReadOnly && (
									<Fragment>
										<div className={ classnames('uk-position-top-left', styles.add) }>
											<Button
												text={ loc.add }
												theme="primary"
												arrow={ true }
												disabled={ isLoading }
												onClick={ this.onClickAddHandler }
											/>
										</div>
										<div className="uk-position-bottom-right">
											<Button
												text={ loc.save }
												theme="primary"
												arrow={ true }
												disabled={ isLoading }
												onClick={ this.onClickSaveHandler }
											/>
										</div>
									</Fragment>
								)
							}
							<div className={ styles.summary }>
								<div>
									<div className={ styles.balance }>{ loc.balance }:</div>
									<div className={ styles.debit }>{ convertFloatToPrice(totalDebitAmount) } DR</div>
									<div className={ styles.credit }>{ convertFloatToPrice(totalCreditAmount) } CR</div>
								</div>
								<div>
									<div className={ styles.accumulate }>{ loc.accumulate }:</div>
									<div className={ styles.accumulateValue }>{ convertFloatToPrice(accumulate) }</div>
								</div>
							</div>
						</div>
					</div>
				</LoadingOverlay>

				<ModalSalesman
					isOpen={ showModalSalesman }
					onToggle={ this.onToggleModalSalesmanHandler }
					onSelect={ this.onSelectSalesmanHandler }
					title={ loc.salesmanSearch }
					salesmanType={ showModalSalesman ? 'salesman' : '' }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		cashMovementConfirmInfo: state.cashMovementConfirmInfo,
		cashMovementDetailsInfo: state.cashMovementDetailsInfo,
	}),
	(dispatch) => ({
		getCashMovementDetails: para => dispatch(getCashMovementDetails(para)),
		confirmCashMovement: para => dispatch(confirmCashMovement(para)),
	})
)(PageCashMovementEdit);